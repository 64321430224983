<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 15:39:25
 * @LastEditTime: 2023-03-21 17:44:09
 * @Descripttion: 班级管理
-->
<style lang="scss" scoped>
.classes-grades {
    @include innerPage;
    @include pageHead(-42px);
    @include defalutTable(calc(100% - 162px), 34px);

    .table-tree {
        .el-button--text {

            &:hover {
                color: #6c4ecb;

                .add {
                    color: #fff;
                    background: #6c4ecb;
                    font-size: 14px;
                }
            }
        }
    }
}
::v-deep .is-filterable{
    max-height: 194px;
    height: auto;
    padding-top: 0;
    width: 100%;
}

.dialog-container {

    .grade-dialog {
        width: calc(100% + 28px);
        max-height: 540px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 28px;
    }

    .grade-form,
    .class-form {
        width: 402px;
        margin-left: 42px;
        box-sizing: border-box;
        padding: 40px 0 10px;
    }
    .personnel-form{
        box-sizing: border-box;
        padding: 40px 0;
        ::v-deep {
            .el-input{
                width: auto;
            }
            .el-icon-arrow-left{
                font-weight: bold;
            }
            .el-icon-arrow-right:before{
                color: #FFFFFF !important;
            }
            .el-checkbox {
                padding-right: 28px;
                margin-top: 0px;
                &-group {
                    display: inline-block !important;
                    margin-top: 0px !important;
                }
                &__inner {
                    width: 18px;
                    height: 18px;
                    border-radius: 6px;
                    border: 1px solid #cbc4e1;
                    background: #e8eaf3;
                    overflow: hidden;
                    &::after {
                        content: "\e606";
                        width: 100%;
                        height: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-family: "iconfont";
                        color: #6c4ecb;
                        transform: rotate(0) scaleY(0);
                        top: 0;
                        left: 0;
                    }
                }
                &.is-checked {
                    .el-checkbox__inner {
                    border-color: #6c4ecb;
                    background: #e8eaf3;
                    &::after {
                        transform: rotate(0) scaleY(1);
                    }
                    }
                }
                .is-focus:not(.is-checked) {
                    .el-checkbox__inner {
                    border-color: #cbc4e1;
                    background: #e8eaf3;
                    &::after {
                        transform: rotate(0) scaleY(0);
                    }
                    }
                }
                &.radio .el-checkbox__inner {
                    border-radius: 50%;
                }
                .is-indeterminate{
                    .el-checkbox__inner{
                        background-color: #6C4ECB;
                    }
                }
            }
            .el-button{
                height: 40px;
            }

        }
    }

    .grade-subjects {
        width: 100%;
        border-top: 1px solid #e8e8e8;
        box-sizing: border-box;
        padding-top: 30px;
        padding-bottom: 30px;

        h5 {
            line-height: 30px;
            margin-bottom: 24px;
        }

        .subjects {
            width: 100%;
            box-sizing: border-box;
            padding-left: 62px;
            padding-right: 108px;

            .subject {
                width: 100%;
                height: 46px;
                margin-bottom: 20px;
                position: relative;

                &:nth-last-child(2) {
                    margin-bottom: 28px;
                }

                &--add {
                    width: 48px;
                    height: 48px;
                    background: #6c4ecb;
                    text-align: center;
                    line-height: 48px;
                    color: #f5f7fe;
                    border-radius: 50%;
                    font-size: 20px;
                    cursor: pointer;
                }

                &-form {
                    .el-form-item {
                        width: 480px;
                        margin-right: 30px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .del {
                    position: absolute;
                    top: 4px;
                    right: -108px;
                }
            }
        }
    }
}
</style>

<template>
    <section class="classes-grades">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                    返回
                </el-button>
            </div>
        </div>
        <div class="table-box">
            <div class="table-tree" ref="tableInner">
                <el-table :data="tableData" height="100%" row-key="sccla_id" border default-expand-all
                    :tree-props="{ children: 'child_node', hasChildren: 'hasChildren' }" :indent='0'
                    :row-class-name="rowClassName">
                    <el-table-column align="center" label="年级" min-width="17.22%" type="">
                        <template slot-scope="scope">
                            {{ !scope.row.sccla_name && scope.row.sccla_grade_name || '' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="班级" min-width="14.71%">
                        <template slot-scope="scope">
                            {{ !scope.row.sccla_name && $_.size(scope.row.child_node) == 0 && '-' || scope.row.sccla_name }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" min-width="23.53%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" min-width="15.52%">
                        <template slot-scope="scope">
                            <p class="table-status" v-if="scope.row.sccla_grade > 0 && scope.row.sccla_name"
                                :class="{ failed: scope.row.sccla_status == 20, success: scope.row.sccla_status == 10 }">
                                {{ scope.row.sccla_status == 10 && '启用' || '禁用' }}
                            </p>
                            <p v-if="scope.row.sccla_grade > 0 && !scope.row.sccla_name">-</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="29%">
                        <template slot-scope="scope">
                            <div class="operation-buttons size--2" v-if="scope.row.sccla_grade > 0">
                                <template v-if="!scope.row.sccla_name">
                                    <el-button type="custom_success" plain size="mini"
                                        @click="showDialog('gradeDialog', scope.row)">
                                        年级配置
                                    </el-button>
                                    <el-button type="text" size="mini" @click="showDialog('classDialog', scope.row)">
                                        <i class="iconfont add">&#xe603;</i>
                                        <span>添加班级</span>
                                    </el-button>
                                </template>
                                <template v-else>
                                    <el-button v-if="scope.row.is_elective==1" type="custom_primary" plain size="mini"
                                        @click="showDialog('electiveDialog', scope.row)">
                                        学生管理
                                    </el-button>
                                    <el-button type="custom_primary" plain size="mini"
                                        @click="showDialog('classDialog', scope.row)">
                                        编辑
                                    </el-button>
                                    <el-button type="custom_danger" plain size="mini" @click="delClass(scope.row.sccla_id)">
                                        删除
                                    </el-button>
                                </template>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 配置年级 -->
        <el-dialog title="年级配置" width="740px" :show-close="false" :visible.sync="gradeDialog"
            @close="hideDialog('gradeForm')">
            <div class="dialog-container">
                <div class="grade-dialog">
                    <el-form label-width="42px" ref="gradeForm" :model="gradeForm">
                        <div class="grade-form">
                            <el-form-item label="年级" prop="schedi_sub_grade">
                                <el-input v-model.trim="gradeForm.sccla_grade_name" readonly />
                            </el-form-item>
                        </div>
                        <div class="grade-subjects">
                            <h5 class="bold">授课科目绑定</h5>
                            <ul class="subjects">
                                <li class="subject" v-for="(item, index) in gradeForm.relevance" :key="index">
                                    <el-form-item label="科目教材" label-width="68px" :prop="'relevance.' + index + '.subject'"
                                        :rules="{ required: true, message: '请选择科目教材', trigger: 'change', type: 'array' }">
                                        <el-cascader v-model="item.subject" :options="editionTree"
                                            :props="{ value: 'key', label: 'value', children: 'child_node' }" />
                                    </el-form-item>
                                    <el-button class="normal del iconfont" type="custom_primary" circle
                                        @click="delSubject(index)" v-if="index > 0">
                                        &#xe620;
                                    </el-button>
                                </li>
                                <li class="subject--add iconfont" @click="addSubject">&#xe603;</li>
                            </ul>
                        </div>
                    </el-form>
                </div>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('gradeForm')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="setGrade" :disabled="btnload" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 新增/编辑 班级 -->
        <el-dialog :title="classForm.sccla_id && '编辑' || '新增班级'" width="558px" :show-close="false"
            :visible.sync="classDialog" @close="hideDialog('classForm')">
            <div class="dialog-container">
                <div class="class-form">
                    <el-form label-width="42px" ref="classForm" :model="classForm" :rules="classRules">
                        <el-form-item label="年级">
                            <el-input v-model.trim="classForm.sccla_grade_name" readonly />
                        </el-form-item>
                        <el-form-item label="班级">
                            <el-input v-model.trim="classForm.sccla_name" maxlength="5" placeholder="请输入班级" />
                        </el-form-item>
                        <el-form-item label="设备">
                            <el-input v-model.trim="classForm.apmac" maxlength="100" placeholder="请输入设备号" />
                        </el-form-item>
                        <el-form-item label="选修">
                            <el-switch v-model="classForm.is_elective" :active-value="1" :inactive-value="2" :active-text="classForm.is_elective == 1 && '选修班' || '非选修班'"></el-switch>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-switch v-model="classForm.sccla_status" :active-value="10" :inactive-value="20"
                                :active-text="classForm.sccla_status == 10 && '启用' || '禁用'" />
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('classForm')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="addClass" :disabled="btnload" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>


         <!-- 虚拟班级人员管理 -->
         <el-dialog :title="'人员管理'" width="900px" :show-close="false"
            :visible.sync="electiveDialog" @close="hideDialog('electiveForm')">
            <div class="dialog-container">
                <div class="personnel-form" style="width:100%;text-align: center;">
                    <el-form label-width="42px" ref="electiveForm" :model="electiveForm" :rules="classRules">
                        <el-transfer
                            style="text-align: left; display: inline-block"
                            v-model="electiveForm.userIds"
                            filterable
                            v-loading="electiveLoading"
                            :render-content="renderFunc"
                            :titles="['年级学生', '本班级学生']"
                            :button-texts="['移除', '加入']"
                            :format="{
                                noChecked: '${total}',
                                hasChecked: '${checked}/${total}'
                            }"
                            @change="handleChange"
                            :data="userData">
                        <!-- <el-button class="transfer-footer" slot="left-footer" size="small">操作</el-button>
                        <el-button class="transfer-footer" slot="right-footer" size="small">操作</el-button> -->
                        </el-transfer>              
                    </el-form>
                </div>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog('electiveForm')">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="addElectiveForm" :disabled="saveLoad" v-loading="saveLoad">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $classTree, $classAdd, $classEdit,$editElectiveForm, $classDel, $gradeSet, $getGrad ,$getGradeUserList} from "@api/home"
import { mapState, mapActions } from "vuex"
export default {
    name: 'user_classesGrades',
    computed: {
        ...mapState('common', ['editionTree'])
    },
    data() {
        return {
            tableData: [], // 列表数据
            /* 年级 */
            gradeDialog: false, // 显示弹窗
            gradeForm: {}, // 年级表单
            /* 班级 */
            classDialog: false, // 显示弹窗
            classForm: {
                is_elective:2,
            }, // 班级表单
            classRules: {
                sccla_name: [{
                    required: true, message: '请输入班级', trigger: 'blur',
                }]
            },
            btnload: false,
            userData: [],
            electiveLoading:false,
            electiveDialog: false, // 显示弹窗
            saveLoad:false,
            electiveForm:{
                userIds:[],
            },
            renderFunc(h, option) {
             return <span> { option.label }</span>;
            }
        }
    },
    created() {
        this.getData();
    },
    methods: {
        ...mapActions('common', ['getClass', 'getEditionTree']),
        /** 获取班级树状列表 */
        async getData() {
            let { data } = await $classTree();
            data.map(item => {
                item.sccla_id = item.sccla_grade;
                if (item.child_node.length) {
                    item.child_node.push({ sccla_id: 0 - item.sccla_grade }) // 添加表格缺省行
                }
            })
            console.log(data);
            this.tableData = data;
            this.$forceUpdate();
        },
        async getGradeUserList(sccla_grade,sccla_id){
            this.electiveLoading = true;
            let { data:data } = await $getGradeUserList({grade_id:sccla_grade,class_id:sccla_id});
            this.electiveLoading = false;
            this.userData =data.userAll;
            this.electiveForm.userIds =data.selectUserIds;
        },
        /** 配置年级 */
        setGrade() {
            this.$refs.gradeForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let { relevance } = this.gradeForm;
                    let params = { schedi_sub_grade: this.gradeForm.schedi_sub_grade, relevance: [] }
                    relevance.map(item => {
                        let subject = {
                            sysub_id: item.subject[0],
                            syedi_id: item.subject[2],
                        }
                        params.relevance.push(subject)
                    })
                    let res = await $gradeSet(params);
                    this.btnload = false
                    if (res) {
                        this.hideDialog('gradeForm');
                        this.$message.success('配置保存成功');
                        this.getData();
                    }
                }
            });
        },
        /**
         *  获取年级信息
         * @param {number} grade 年级编码
         */
        async getGrad(grade) {
            let { data } = await $getGrad(grade);
            if (!this.$isEmpty(data.relevance)) {
                let relevance = [];
                data.relevance.map(item => {
                    let relevanceItem = {
                        subject: [item.sysub_id, item.syedi_id_pid, item.syedi_id]
                    }
                    relevance.push(relevanceItem)
                });
                this.gradeForm.relevance = [...relevance];
            }
            this.$forceUpdate();
        },
        /** 添加班级 */
        addClass() {
            this.$refs.classForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let params = { ...this.classForm };
                    delete params.sccla_grade_name;
                    if (params.sccla_id) return this.editClass(params);
                    let res = await $classAdd(params);
                    this.btnload = false;
                    if (res) {
                        this.hideDialog('classForm');
                        this.$message.success('添加成功');
                        this.getData();
                        this.getClass();
                    }
                }
            });
        },
         /** 人员管理 */
       async addElectiveForm() {
            this.$refs.electiveForm.validate(async (valid) => {
                if (valid) {
                    this.saveLoad = true;
                    let params = { ...this.electiveForm };
                    let res = await $editElectiveForm(params);                   
                    this.saveLoad = false;
                    if (res) {
                        this.hideDialog('electiveForm');
                        this.$message.success('编辑成功');
                        // this.getData();
                        // this.getGradeClass();
                    }
                }
            });
        },
        /**
         * 编辑班级
         * @param {object} params 请求参数
         */
        async editClass(params) {
            let res = await $classEdit(params);
            this.btnload = false;
            if (res) {
                this.hideDialog('classForm');
                this.$message.success('编辑成功');
                this.getData();
                this.getClass();
            }
        },
        /**
         * 删除班级
         * @param {number} id 班级id
         */
        delClass(id) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前班级？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await $classDel(id);
                if (res) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '当前班级已删除！'
                    });
                    this.getData();
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 添加年级科目 */
        addSubject() {
            const obj = { subject: [] }
            this.gradeForm.relevance.push(obj);
            this.$forceUpdate();
        },
        /** 移除年级科目 */
        delSubject(index) {
            let relevance = this.gradeForm.relevance;
            this.$_.pullAt(relevance, index)
            this.gradeForm.relevance = [...relevance];
            this.$forceUpdate();
        },
        /**
         * 表格行类名动态添加
         * @param {object} row 表格行数据
         */
        rowClassName({ row }) {
            if (row.sccla_id < 0) return 'empty';
        },
        handleChange(value, direction, movedKeys) {
        
    },
        /** 打开弹窗 */
        async showDialog(ref, data) {
            let classForm = {}, gradeForm = {}
            if (ref == 'gradeDialog') await this.getEditionTree(data.sccla_grade);
            this[ref] = true;
            if (ref == 'classDialog') {
                classForm = {
                    sccla_grade: data.sccla_grade,
                    sccla_grade_name: data.sccla_grade_name,
                    sccla_status: 10,
                    is_elective: data.is_elective,
                }
                if (data.sccla_id != data.sccla_grade) {
                    classForm.sccla_id = data.sccla_id
                    classForm.sccla_name = data.sccla_name;
                    classForm.sccla_status = data.sccla_status
                    classForm.apmac = data.apmac
                    classForm.is_elective = data.is_elective
                }
                this.classForm = { ...classForm }
            }
            else if(ref == 'electiveDialog'){               
               
               this.electiveForm={
                   userIds:[],
                   sccla_id:data.sccla_id
               };
               this.userAll=[];
                //获取窗穿梭框中的值
               this.getGradeUserList(data.sccla_grade,data.sccla_id);
           }else  {
                gradeForm = {
                    schedi_sub_grade: data.sccla_grade,
                    sccla_grade_name: data.sccla_grade_name,
                    relevance: [{
                        subject: []
                    }],
                }
                this.gradeForm = { ...gradeForm }
                this.getGrad(data.sccla_grade);
            }
        },
        /** 关闭弹窗 */
        hideDialog(ref) {
            this.gradeDialog = false;
            this.classDialog = false;
            this.electiveDialog = false;
            this.resetForm(ref);
            this[ref] = {};

        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        }
    },
}
</script>